.header {
  background: #5061ff;
  color: #fff;
  padding: 1em 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: auto;
  height: 50px;
  margin-right: 15px;
}

.site-title {
  font-size: 1.5em;
  margin: 0;
  color: #000000;
}

.header nav ul {
  list-style: none;
  padding: 0;
}

.header nav ul li {
  display: inline;
  margin-right: 1em;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
}
